.textabout{
    position: relative;
    width: 461px;
    left: 126px;
top:-45px;

font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 17.2444px;
line-height: 20px;

color: #253D8A;

}
.numabout{
    font-family: 'Hind Siliguri';
font-style: normal;
font-weight: 700;
font-size: 18.9052px;
line-height: 31px;
/* identical to box height */

color: #30C0C6;
}
.text2about{
    font-family: 'Hind Siliguri';
    font-style: normal;
    font-weight: 700;
    font-size: 17.1865px;
    line-height: 28px;
/* identical to box height */

color: #253D8A;
}
.text3about{
    font-family: 'Hind Siliguri';
font-style: normal;
font-weight: 500;
font-size: 11.1713px;
line-height: 18px;
/* identical to box height */

color: #253D8A;
}
.text4about{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14.8981px;
    line-height: 17px;
    text-align: center;
    width: 130px;
    color: #253D8A;
/* identical to box height */

color: #253D8A;
}
.text5about{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 25.9834px;
line-height: 30px;
display: flex;
justify-content: center;
margin-left: 140px;
margin-right: 140px;
color: #253D8A;

}
.textvisionabout{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 37.669px;
line-height: 43px;
/* identical to box height */
margin-left:  124.59px;
margin-top: -60px;

color: #FFFFFF;
}
.textvision{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 41px;
margin-left: 102px;
margin-top: -30px;
color: #FFFFFF;
}
.linevisionabout {
    margin-left: 106px;
    margin-top: 24.35px;
    height: 62.43px;
}
.textvaleurvision1{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 169.99%;
/* or 41px */
margin-left:13px;

color: #30C0C6;
}
.textvaleurvision2{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 169.99%;
/* or 41px */


color: rgba(37, 61, 138, 1)
;
}
.buttondemo{
    background: #253D8A;
box-shadow: 0px 2.9614px 15.5474px -1.4807px rgba(0, 0, 0, 0.09);
border-radius: 74.0351px;
display: flex;
width: 205.08px;
border: none;
height: 45.9px;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 14.807px;
gap: 7.4px;
}
.textbuttondemo{
    color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
@media screen and (max-width:992px) {
    .textabout{
        display: flex;
        justify-content: center;
        width: 261px;
        left: 0px;
    top:0px;
    text-align: center;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 17.2444px;
    line-height: 20px;
    
    color: #253D8A;
    
    }
    .numabout{
        font-family: 'Hind Siliguri';
    font-style: normal;
    font-weight: 700;
    font-size: 18.9052px;
    line-height: 31px;
    /* identical to box height */
    margin-left: 58.11px;
    
    color: #30C0C6;
    }
    .text2about{
        font-family: 'Hind Siliguri';
        font-style: normal;
        font-weight: 700;
        font-size: 17.1865px;
        line-height: 28px;
    /* identical to box height */
    margin-left: 37.49px;
    
    color: #253D8A;
    }
    .text3about{
        font-family: 'Hind Siliguri';
    font-style: normal;
    font-weight: 500;
    font-size: 11.1713px;
    line-height: 18px;
    /* identical to box height */
    margin-left: 36.46px;
    
    color: #253D8A;
    }
    .text4about{
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 14.8981px;
        line-height: 17px;
        text-align: center;
        width: 130px;
        color: #253D8A;
    /* identical to box height */
    margin-left: 16px;
    
    color: #253D8A;
    }
    .text5about{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 25.9834px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    margin-left: 60px;
    margin-right: 60px;
    color: #253D8A;
    
    }
    .textvisionabout{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 37.669px;
    line-height: 43px;
    /* identical to box height */
    margin-left:  124.59px;
    margin-top: -60px;
    
    color: #FFFFFF;
    }
    .textvision{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 41px;
    display: flex;
    justify-content: center;
    width: 300px;
    margin-left: 20px;
    margin-top: -30px;
    color: #FFFFFF;
    }
    .linevisionabout {
        margin-left: 106px;
        margin-top: 24.35px;
        height: 62.43px;
    }
    .textvaleurvision1{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 169.99%;
    /* or 41px */
    margin-left:63px;
    
    color: #30C0C6;
    }
    .textvaleurvision2{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 169.99%;
    /* or 41px */
    
    
    color: rgba(37, 61, 138, 1)
    ;
    }
    .buttondemo{
        background: #253D8A;
    box-shadow: 0px 2.9614px 15.5474px -1.4807px rgba(0, 0, 0, 0.09);
    border-radius: 74.0351px;
    display: flex;
    width: 205.08px;
    border: none;
    height: 45.9px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14.807px;
    gap: 7.4px;
    }
    .textbuttondemo{
        color: #FFFFFF;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
    }
}