.sliderRecrutement{
    box-sizing: border-box;
    z-index: 12;
    background: #FFFFFF;
    border: 0.885014px solid #30C0C6;
    border-radius: 6.1951px;
    width: 60%;
    display:flex;justify-content:center;
    align-items: center;

}
.filterrecurtemnt{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 15.6618px;
line-height: 160%;
width: 20%;
height: 38.06px;
/* identical to box height, or 25px */
border-left: none;
border-right: none;
border-top:none ;
border-color:#253D8A ;
display: flex;
align-items: center;
letter-spacing: 0.48943px;
margin-left: 15px;
color: #253D8A;
}

::placeholder {

    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 15.6618px;
line-height: 160%;
/* identical to box height, or 25px */

display: flex;
align-items: center;
letter-spacing: 0.48943px;

color: #253D8A;
  }
  .titrecardrecrutement{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 22.9704px;
line-height: 160%;
/* identical to box height, or 37px */

display: flex;
align-items: center;
letter-spacing: 0.409777px;

color: rgba(37, 61, 138, 0.8);
  }
  .soustitrecardrecrutement{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 14.8196px;
line-height: 160%;
/* identical to box height, or 24px */

letter-spacing: 0.409777px;

color: rgba(37, 61, 138, 0.8);

  }
  .textcardrecrutement{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 14.8196px;
line-height: 160%;
/* identical to box height, or 24px */

letter-spacing: 0.409777px;

color: rgba(37, 61, 138, 0.8);
  }
  .buttoncardrecrutement{
    width:
79.08px !important;
height:
21.91px !important;
display: flex;
margin-left: 15px;
border: none;
align-items: center;
justify-content: center;
    background: rgba(37, 61, 138, 0.8);
border-radius: 3.81093px;
  }
  .textbuttoncardrecrutement{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 14.8196px;
line-height: 160%;
/* or 24px */


letter-spacing: 0.409777px;

color: rgba(255, 255, 255, 0.8);

  }
  .titlerecutemment{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 160%;
/* or 51px */
margin-top: 83px;
display: flex;
align-items: center;
letter-spacing: 0.48943px;
justify-content: center;
color: #253D8A;
  }
  .buttonpdfrecrutement{
    width: 100%;
    height: 69px;
    display: flex;
    justify-content: center;
    border: none;
    background: rgba(37, 61, 138, 0.63);
border-radius: 7px;
transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .textbuttonpdfrecrutement{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 22.7036px;
line-height: 160%;
/* or 36px */
justify-content: center

;
display: flex;
align-items: center;
letter-spacing: 0.709486px;

color: #FFFFFF
  }
  .cardrecrutement:hover {
    
box-sizing: border-box;

background: #FFFFFF;
border-left: 2px solid #30C0C6;
filter:drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
  }
 .cardrecrutement:hover .titrecardrecrutement{
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 22.9704px;
  line-height: 160%;
  /* identical to box height, or 37px */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.409777px;
  
color: #30C0C6;
 }
 .cardrecrutement:hover .soustitrecardrecrutement{
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14.8196px;
  line-height: 160%;
  /* identical to box height, or 24px */
  
  letter-spacing: 0.409777px;
  
color: #30C0C6;
 }
 
 .cardrecrutement:hover .buttoncardrecrutement{
  width: 100%;
  box-sizing: border-box;

  height: 69px;
  border: none;
  background: rgba(37, 61, 138, 0.63);
border-radius: 7px;
transform: matrix(1, 0, 0, 1, 0, 0);
background: #30C0C6;

 }
 
.cardrecrutement:hover .textbuttoncardrecrutement{
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 15.5549px;
  line-height: 160%;
  /* or 25px */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.430107px;
  
  color: #FFFFFF;
 }
 .pagination {
  --bs-pagination-color: #4D4F5C
 }
 .active>.page-link, .page-link.active {
  background-color: #30C0C6 !important;
 }
