.buttoncontact{
    width:
    202px;
    height:
    38.29px;
    background: #253D8A;}
.textbuttoncontact{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16.2231px;
    line-height: 20px;
    /* identical to box height */
    
    
    color: #FFFFFF;
}
.textcardcontact{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12.9785px;
    line-height: 19px;
    
    color: rgba(37, 61, 138, 0.8);
}
.soustextcardcontact{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12.9785px;
    line-height: 19px;
    
    color: #253D8A;
}
#image{
	margin-top: 10px;
	box-shadow: 5px 5px 5px 5px gray;
	width: 100px;;
	padding: 20px;
	font-weight: 400;
	padding-bottom: 0px;
	height: 60px;
	user-select: none;
	text-decoration:line-through;
	font-style: italic;
	font-size: x-large;
	border: #4245f3 2px solid;
	margin-left: 20px;
	
}
#user-input{
	box-shadow: 5px 5px 5px 5px gray;
	width:150px;
	margin-right: 10px;
	padding: 10px;
	padding-bottom: 0px;
	height: 40px;
	/* border: red 0px solid; */
}
input{
	/* border:1px black solid; */
    width: 130px;
}
/* .inline{ */
	/* display:inline-block; */
/* } */
#btn{
	box-shadow: 5px 5px 5px grey;
	color: rgb(255, 255, 255);
	margin: 10px;
	background-color: rgb(61, 42, 165);
    border-radius: 5px;
}
#key{
    color : red;
    font-weight: 100;
    font-size: large;
    margin-left: 10px;
}
