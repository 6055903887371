.footertitre1{
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 17.0625px;
line-height: 20px;
/* identical to box height */

margin-top: -65px;
margin-left: 542px;
color: #FFFFFF;
}
.footertext1{
    font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 13.27px;
line-height: 16px;
/* identical to box height */
margin-left: 542px;
cursor: pointer;


color: #FFFFFF;
}
.footertitre2{
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 17.0625px;
line-height: 20px;
/* identical to box height */

margin-top: 51px;

color: #FFFFFF;
}
.footertitrebis{
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 12.0625px;
line-height: 20px;
/* identical to box height */

margin-top: 51px;

color: #FFFFFF;
}
.footertext2{
    font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 13.27px;
line-height: 16px;
/* identical to box height */
width: 288px;
cursor: pointer;

color: #FFFFFF;
}
.footer {
    background: #253D8A;
    height: 337px;
    margin-top: 92px !important;
}
.footertitre3{
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 17.0625px;
line-height: 20px;
/* identical to box height */

margin-top: -195px;
margin-left: 245px;
color: #FFFFFF;
}
.footertext3{
    font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 13.27px;
line-height: 16px;
/* identical to box height */
margin-left: 245px;



color: #FFFFFF;
}
.footerinput{
    background: #F5F5F5;
border-radius: 23.6979px;
margin-left: 45px;
margin-top: 37px;
border: none;

}
@media screen and (max-width: 1280px ) and (min-width: 768px) {
    .footertitre1{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 17.0625px;
    line-height: 20px;
    /* identical to box height */
    
    margin-top: 60px;
    margin-left: 42px;
    color: #FFFFFF;
    }
    .footertext1{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13.27px;
    line-height: 16px;
    /* identical to box height */
    margin-left: 42px;
    
    
    
    color: #FFFFFF;
    }
    .footertext2{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13.27px;
    line-height: 16px;
    /* identical to box height */
    
    
    
    
    color: #FFFFFF;
    }
    .footertitre3{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 17.0625px;
    line-height: 20px;
    /* identical to box height */
    
    margin-top: 49px;
    margin-left: 42px;
    color: #FFFFFF;
    }
    .footertext3{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13.27px;
    line-height: 16px;
    /* identical to box height */
    margin-left: 42px;
    
    
    
    color: #FFFFFF;
    }
    .footerinput{
        background: #F5F5F5;
    border-radius: 23.6979px;
    margin-left: 8px;
    margin-top: 5px;
    border: none;
    
    }
}
@media screen and (max-width: 992px )  {
    .footer {
        background: #253D8A;
        min-height: 437px;
        margin-top: 92px !important;
        height: auto;
    }
    .footertitre1{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 17.0625px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    justify-content: center;
    margin-top: 49px;
    margin-left: 0px;
    color: #FFFFFF;
    }
    .footertext1{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13.27px;
    line-height: 16px;
    /* identical to box height */
    margin-left: 0px;
    display: flex;
    justify-content: center;
    
    
    color: #FFFFFF;
    }
    .footertext2{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13.27px;
    line-height: 16px;
    /* identical to box height */
    
    
    display: flex;
    justify-content: center;
    
    color: #FFFFFF;
    }
    .footertitre3{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 17.0625px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    justify-content: center;
    margin-top: 49px;
    margin-left: 0px;
    color: #FFFFFF;
    }
    .footertext3{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13.27px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    /* identical to box height */
    margin-left: 0px;
    
    
    
    color: #FFFFFF;
    }
    .footerinput{
        background: #F5F5F5;
    border-radius: 23.6979px;
    margin-left: 0px;
    margin-top: 5px;
    
    border: none;
    
    }
    .footertitre2{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 17.0625px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    justify-content: center;
    margin-top: 49px;
    
    color: #FFFFFF;
    }
}