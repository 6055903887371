/*Slider*/
.sidebar {
    background-image: url('../../assets/Slider.png');
    height:581px;
    width: 100%;
}
.css-ohwg9z {
    overflow: visible !important;
}
.css-1m9128y {
    display: none  !important;
}
.css-1abc02a{
    right: 250px !important;
}
.css-hn784z {
    left: 250px !important;
}
.buttonslider{
    background: rgba(48, 192, 198, 0.31);
border-radius: 17px;
height: 63px;
width: 359px;
margin-left: 100px;
align-self: center;
border: none;
cursor: pointer; font-family: 'Poppins';
font-style: normal;
font-weight: 700;

font-size: 30px;
line-height: 45px;
display: flex;
align-items: center;
justify-content: center;
color: #FFFFFF;
}

.buttonslider2{
    background: rgba(48, 192, 198, 0.31);
border-radius: 17px;
height: 63px;
width: 30%;
margin-top: 50px;
border: none;

}
.buttonslider3{
    background: rgba(48, 192, 198, 0.31);
border-radius: 17px;
height: 63px;
width: 30%;
margin-top: -50;
border: none;

}
.buttonslider4{
    background: rgba(48, 192, 198, 0.31);
    border-radius: 17px;
    height: 63px;
    width: 30%;
    margin-top: 50px;
    border: none;

}

.carousel-control-prev{
    left: auto !important;
    right: 135px!important;
    width: 25px !important;
    margin-top: 27%;

}
.carousel-control-next{
   width: 25px !important;
   right: 95px!important;
    margin-top: 27%;

}
.carousel-indicators{
    justify-content: left !important;
    bottom: 70px !important;
    margin-left: 5%  !important;
    
}
.carousel-indicators >button{ width: 15px !important; height: 15px !important; border-radius: 100%; }

.carousel-caption {
    height: 100%;
}


.carousel-control-prev-icon{
    background-color: black;
    border-radius: 50px;
    background-size: 25px !important;

}
.carousel-control-next-icon {
    background-color: black;
    border-radius: 50px;
    background-size: 25px !important;

}
.carousel-control-prev1{
    right: 0% !important;
    left:auto !important;
    margin-right: 50px;
    margin-top: 350px;
}
.carousel-control-next1{

    margin-top: 30%;
}
/*Components /*
/*metier */
.metier{
    background: linear-gradient(101.03deg, #46B7B7 0%, #FFFFFF 100%);
    min-height: 604px;
    height: auto;
    margin-top: 42px !important;
}
.line {
    margin-left: 115px;
    margin-top: 86px;
}
.titlemetier {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 700;
font-size: 54.6105px;
line-height: 63px;
position: relative;
width: 100%;
height: 63px;
left: 126px;
top:-75px;
color: #253D8A;
}
.textmetier {
    position: relative;
    width: 682px;
height: 116px;
left: 127px;
font-weight: 400;
margin-top:-25px;

font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 29px;

color: #253D8A;

}
.ITU{
    position: relative;
    top:234px;
}
.KPMG{
    position: relative;
    top:386px;
    left:-126px; 
}
.textitemmetier {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 400;
font-size: 27.9048px;
line-height: 32px;
/* identical to box height */
margin-top: -25px;
margin-left: 180.53px;
color: #253D8A;
}
.textitemmetier2 {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 400;
font-size: 27.9048px;
line-height: 32px;
/* identical to box height */
margin-top: -25px;
margin-left: 180.53px;
color: #253D8A;
}
/*Chiffres */
.chiffresback{
    background-image: url("../../assets/chiffres.svg");
    height: 690px;
    background-size: 100%;
    align-items:center;    display: flex;justify-content: center;
}
.titrechiffre {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 700;
font-size: 47.1586px;
line-height: 54px;
color: #FFFFFF;
}
/*Presence */

.titleprecence{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 54.6105px;
    line-height: 63px;
    position: relative;
    width: 100%;
    height: 63px;
    left: 126px;
    top:-75px;
    color: #253D8A;
}
.line2{
    margin-left: 35px;
    margin-top: 290px;
    height: 91px;

}
.titlePrecense1{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 126px;
    top:-75px;
    
    color: #30C0C6;
     
}
.soustitlePrecense1{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5155px;
    line-height: 21px;
    
    color: #30C0C6;
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 126px;
    top:-90px;
    
     
}
.line3{
    margin-left: 35px;
    margin-top: 290px;
    height: 91px;

}
.titlePrecense2{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    
    
    color: #8BAEE4;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 195px;
    top:-95px;
    
     
}
.soustitlePrecense2{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5155px;
    line-height: 21px;
    
    color:  #8BAEE4;
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 195px;
    top:-95px;
    
     
}
.line4{
    margin-left: 35px;
    margin-top: 340px;
    height: 91px;

}
.titlePrecense3{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 126px;
    top:-75px;



color: #253D8A;
     
}
.soustitlePrecense3{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5155px;
    line-height: 21px;
    
    color: #253D8A;
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 126px;
    top:-90px;
    
     
}
/*Services & Solutions*/
.card{
    background: #FFFFFF !important;
box-shadow: 0px 12px 24px rgba(44, 225, 194, 0.2) !important;
border-radius: 15px !important;
width:290px !important;
height: 321px !important;
margin-left: 50px;
margin-right: 50px;

margin-top: 46px !important;
}
.buttoncardservice{
    background: #30C0C6;
border-radius: 7.69231px;
width: 247.69px;
height: 38.46px;
margin-top: 90px;
border: none;
position: absolute;
bottom: 17.54px;
}
.buttoncardservice2{
    background: #30C0C6;
border-radius: 7.69231px;
width: 147.69px;
height: 38.46px;
/* margin-top: 40px; */
border: none;
position: absolute;
bottom: 17.54px;
}
.textbuttoncardservice{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 23.0769px;
line-height: 27px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}
.card-text {
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    
    color: #253D8A; 
}
@media screen and (max-width: 1280px ) and (min-width: 992px) {
    .metier{
        background: linear-gradient(101.03deg, #46B7B7 0%, #FFFFFF 100%);
        min-height:804px;
        height: auto;
        margin-top: 42px !important;
    }
    .ITU{
        position: relative;
        top: -100px;
    }
    .KPMG{
        position: relative;
        top:0px;
        left:-126px; 
    }
.titleprecence{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 54.6105px;
    line-height: 63px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 63px;
    top:100px;
    left:0px;
    color: #253D8A;
    
}

.titlePrecense1{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 25px;
    top:0px;
    
    color: #30C0C6;
     
}
.line2{
    margin-left: 35px;
    margin-top: -20px;
    height: 91px;
}
.soustitlePrecense1{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5155px;
    line-height: 21px;
    
    color: rgba(48, 192, 198, 0.5);
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 35px;
    top:-10px;
    
     
}
.line3{
    margin-left: 35px;
    margin-top: -20px;
    height: 91px;

}
.titlePrecense2{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    
    
    color: #8BAEE4;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 35px;
    top:0px;
    
     
}
.soustitlePrecense2{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5155px;
    line-height: 21px;
    
    color: rgba(139, 174, 228, 0.5);
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 35px;
    top:-20px;
    
     
}
.line4{
    margin-left: 35px;
    margin-top: 0px;
    height: 91px;

}
.titlePrecense3{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 126px;
    top:-80px;



color: #253D8A;
     
}
.soustitlePrecense3{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5155px;
    line-height: 21px;
    
    color:  rgba(37, 61, 138, 0.5);
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 126px;
    top:-90px;
    
     
}

}
@media screen and (max-width: 992px )  {
    /*Slider*/
.sidebar {
    background-image: url('../../assets/Slider.png');
    height:581px;
    width: 100%;
}
.buttonslider{
    background: rgba(48, 192, 198, 0.31);
border-radius: 17px;
height: 43px;
width: 190px;
margin-top: 25px;
margin-left: 50px;
border: none;

}
.textbuttonslider{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 45px;
display: flex;
align-items: center;
justify-content: center;
color: #FFFFFF;
}
.buttonslider2{
    background: rgba(48, 192, 198, 0.31);
border-radius: 17px;
height: 63px;
width: 30%;
margin-top:139px;
border: none;

}
.buttonslider3{
    background: rgba(48, 192, 198, 0.31);
border-radius: 17px;
height: 63px;
width: 30%;
margin-top: -50;
border: none;

}
.buttonslider4{
    background: rgba(48, 192, 198, 0.31);
    border-radius: 17px;
    height: 63px;
    width: 30%;
    margin-top: 50px;
    border: none;

}


/*Components /*
/*metier */
.metier{
    background: linear-gradient(101.03deg, #46B7B7 0%, #FFFFFF 100%);
    min-height: 704px;
    height: auto;
    margin-top: 42px !important;
}
.line {
    margin-left: 115px;
    margin-top: 86px;
}
.titlemetier {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 700;
font-size: 34.6105px;
line-height: 63px;
width: 100%;
height: 63px;
display: flex;justify-content: center;
left: 0px;
top:20px;
color: #253D8A;
}
.textmetier {
    display: flex;justify-content: center;
    top:20px;    
height: 116px;
text-align: center;
font-weight: 400;
margin-top:25px;
width: 100%;
left: 10px;
font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 29px;

color: #253D8A;

}
.ITU{
  
    top:-100px;
    left: 90px;
}
.KPMG{
    
    top: 0px;
    left:-40px; 
}
.textitemmetier {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 400;
font-size: 23.9048px;
line-height: 32px;
/* identical to box height */
margin-top: -7px;
display: flex;
height: 15px;
margin-left: 20px;
justify-content: center;
color: #253D8A;
}
.textitemmetier2 {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 400;
font-size: 23.9048px;
line-height: 32px;
/* identical to box height */
display: flex;
height: 15px;
margin-left: 20px;
margin-top: -7px;

justify-content: center;
color: #253D8A;
}
/*Chiffres */
.chiffresback{
    background-image: url("../../assets/chiffres.svg");
    height: 188px;
    background-size: 100%;
    align-items:center;    display: flex;justify-content: center;
}
.titrechiffre {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 700;
font-size: 27.1586px;
line-height: 54px;
color: #FFFFFF;
}
/*Presence */


.line2{
    margin-left: 25px;
    margin-top: 0px;
    height: 71px;

}
.titlePrecense1{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 37px;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 20px;
    top:15px;
    
    color: #30C0C6;
     
}
.soustitlePrecense1{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 13.5155px;
    line-height: 21px;
    
    color: rgba(48, 192, 198, 0.5);
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 20px;
    top:-10px;
    
     
}
.line3{
    margin-left: 25px;
    margin-top: 0px;
    height: 71px;
}
.titlePrecense2{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 37px;
    /* identical to box height */
    
    
    color: #8BAEE4;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 20px;
    top:15px;
    
     
}
.soustitlePrecense2{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 13.5155px;
    line-height: 21px;
    
    color: rgba(139, 174, 228, 0.5);
    
    /* identical to box height */
    position: relative;
    width: 244px;
    height: 63px;
    left: 20px;
    top:-10px;
     
}
.line4{
    margin-left: 25px;
    margin-top: 0px;
    height: 71px;
}
.titlePrecense3{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 37px;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 20px;
    top:15px;



color: #253D8A;
     
}
.soustitlePrecense3{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 13.5155px;
    line-height: 21px;
    
    color:  rgba(37, 61, 138, 0.5);
    
    /* identical to box height */
    position: relative;
    width: 240px;
    height: 63px;
     left: 20px;
    top:-10px;
     
}
/*Services & Solutions*/
.card{
    background: #FFFFFF !important;
box-shadow: 0px 12px 24px rgba(44, 225, 194, 0.2) !important;
border-radius: 15px !important;
width:290px !important;
height: 341px !important;
margin-left: 50px;
margin-right: 50px;

margin-top: 46px !important;
}
.buttoncardservice{
    background: #30C0C6;
border-radius: 7.69231px;
width: 247.69px;
height: 38.46px;
margin-top: 90px;
border: none;
position: absolute;
bottom: 17.54px;
}
.textbuttoncardservice{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 23.0769px;
line-height: 27px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}
.card-text {
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    
    color: #253D8A; 
}
    .titleprecence{
        font-family: 'Tw Cen MT';
        font-style: normal;
        font-weight: 700;
        font-size: 34.6105px;
        line-height: 63px;
        display: flex;
        justify-content: center;        width: 100%;
        height: 63px;
        left: 0px;
        top:50px;
        color: #253D8A;
    }
    .cSNndH{display: flex !important;}
    .carousel-indicators{
        justify-content: left !important;
        bottom: 3px !important;
        left: -40px !important;
        
    }
    .carousel-indicators >button{ width: 7px !important; height: 7px !important; border-radius: 100%; }
    

}
