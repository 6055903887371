/*Slider*/
.sidebar {
    background-image: url(/static/media/Slider.57f79fcd.png);
    height:581px;
    width: 100%;
}
.css-ohwg9z {
    overflow: visible !important;
}
.css-1m9128y {
    display: none  !important;
}
.css-1abc02a{
    right: 250px !important;
}
.css-hn784z {
    left: 250px !important;
}
.buttonslider{
    background: rgba(48, 192, 198, 0.31);
border-radius: 17px;
height: 63px;
width: 359px;
margin-left: 100px;
align-self: center;
border: none;
cursor: pointer; font-family: 'Poppins';
font-style: normal;
font-weight: 700;

font-size: 30px;
line-height: 45px;
display: flex;
align-items: center;
justify-content: center;
color: #FFFFFF;
}

.buttonslider2{
    background: rgba(48, 192, 198, 0.31);
border-radius: 17px;
height: 63px;
width: 30%;
margin-top: 50px;
border: none;

}
.buttonslider3{
    background: rgba(48, 192, 198, 0.31);
border-radius: 17px;
height: 63px;
width: 30%;
margin-top: -50;
border: none;

}
.buttonslider4{
    background: rgba(48, 192, 198, 0.31);
    border-radius: 17px;
    height: 63px;
    width: 30%;
    margin-top: 50px;
    border: none;

}

.carousel-control-prev{
    left: auto !important;
    right: 135px!important;
    width: 25px !important;
    margin-top: 27%;

}
.carousel-control-next{
   width: 25px !important;
   right: 95px!important;
    margin-top: 27%;

}
.carousel-indicators{
    justify-content: left !important;
    bottom: 70px !important;
    margin-left: 5%  !important;
    
}
.carousel-indicators >button{ width: 15px !important; height: 15px !important; border-radius: 100%; }

.carousel-caption {
    height: 100%;
}


.carousel-control-prev-icon{
    background-color: black;
    border-radius: 50px;
    background-size: 25px !important;

}
.carousel-control-next-icon {
    background-color: black;
    border-radius: 50px;
    background-size: 25px !important;

}
.carousel-control-prev1{
    right: 0% !important;
    left:auto !important;
    margin-right: 50px;
    margin-top: 350px;
}
.carousel-control-next1{

    margin-top: 30%;
}
/*Components /*
/*metier */
.metier{
    background: linear-gradient(101.03deg, #46B7B7 0%, #FFFFFF 100%);
    min-height: 604px;
    height: auto;
    margin-top: 42px !important;
}
.line {
    margin-left: 115px;
    margin-top: 86px;
}
.titlemetier {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 700;
font-size: 54.6105px;
line-height: 63px;
position: relative;
width: 100%;
height: 63px;
left: 126px;
top:-75px;
color: #253D8A;
}
.textmetier {
    position: relative;
    width: 682px;
height: 116px;
left: 127px;
font-weight: 400;
margin-top:-25px;

font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 29px;

color: #253D8A;

}
.ITU{
    position: relative;
    top:234px;
}
.KPMG{
    position: relative;
    top:386px;
    left:-126px; 
}
.textitemmetier {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 400;
font-size: 27.9048px;
line-height: 32px;
/* identical to box height */
margin-top: -25px;
margin-left: 180.53px;
color: #253D8A;
}
.textitemmetier2 {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 400;
font-size: 27.9048px;
line-height: 32px;
/* identical to box height */
margin-top: -25px;
margin-left: 180.53px;
color: #253D8A;
}
/*Chiffres */
.chiffresback{
    background-image: url(/static/media/chiffres.346637b7.svg);
    height: 690px;
    background-size: 100%;
    align-items:center;    display: flex;justify-content: center;
}
.titrechiffre {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 700;
font-size: 47.1586px;
line-height: 54px;
color: #FFFFFF;
}
/*Presence */

.titleprecence{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 54.6105px;
    line-height: 63px;
    position: relative;
    width: 100%;
    height: 63px;
    left: 126px;
    top:-75px;
    color: #253D8A;
}
.line2{
    margin-left: 35px;
    margin-top: 290px;
    height: 91px;

}
.titlePrecense1{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 126px;
    top:-75px;
    
    color: #30C0C6;
     
}
.soustitlePrecense1{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5155px;
    line-height: 21px;
    
    color: #30C0C6;
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 126px;
    top:-90px;
    
     
}
.line3{
    margin-left: 35px;
    margin-top: 290px;
    height: 91px;

}
.titlePrecense2{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    
    
    color: #8BAEE4;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 195px;
    top:-95px;
    
     
}
.soustitlePrecense2{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5155px;
    line-height: 21px;
    
    color:  #8BAEE4;
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 195px;
    top:-95px;
    
     
}
.line4{
    margin-left: 35px;
    margin-top: 340px;
    height: 91px;

}
.titlePrecense3{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 126px;
    top:-75px;



color: #253D8A;
     
}
.soustitlePrecense3{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5155px;
    line-height: 21px;
    
    color: #253D8A;
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 126px;
    top:-90px;
    
     
}
/*Services & Solutions*/
.card{
    background: #FFFFFF !important;
box-shadow: 0px 12px 24px rgba(44, 225, 194, 0.2) !important;
border-radius: 15px !important;
width:290px !important;
height: 321px !important;
margin-left: 50px;
margin-right: 50px;

margin-top: 46px !important;
}
.buttoncardservice{
    background: #30C0C6;
border-radius: 7.69231px;
width: 247.69px;
height: 38.46px;
margin-top: 90px;
border: none;
position: absolute;
bottom: 17.54px;
}
.buttoncardservice2{
    background: #30C0C6;
border-radius: 7.69231px;
width: 147.69px;
height: 38.46px;
/* margin-top: 40px; */
border: none;
position: absolute;
bottom: 17.54px;
}
.textbuttoncardservice{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 23.0769px;
line-height: 27px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}
.card-text {
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    
    color: #253D8A; 
}
@media screen and (max-width: 1280px ) and (min-width: 992px) {
    .metier{
        background: linear-gradient(101.03deg, #46B7B7 0%, #FFFFFF 100%);
        min-height:804px;
        height: auto;
        margin-top: 42px !important;
    }
    .ITU{
        position: relative;
        top: -100px;
    }
    .KPMG{
        position: relative;
        top:0px;
        left:-126px; 
    }
.titleprecence{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 54.6105px;
    line-height: 63px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 63px;
    top:100px;
    left:0px;
    color: #253D8A;
    
}

.titlePrecense1{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 25px;
    top:0px;
    
    color: #30C0C6;
     
}
.line2{
    margin-left: 35px;
    margin-top: -20px;
    height: 91px;
}
.soustitlePrecense1{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5155px;
    line-height: 21px;
    
    color: rgba(48, 192, 198, 0.5);
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 35px;
    top:-10px;
    
     
}
.line3{
    margin-left: 35px;
    margin-top: -20px;
    height: 91px;

}
.titlePrecense2{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    
    
    color: #8BAEE4;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 35px;
    top:0px;
    
     
}
.soustitlePrecense2{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5155px;
    line-height: 21px;
    
    color: rgba(139, 174, 228, 0.5);
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 35px;
    top:-20px;
    
     
}
.line4{
    margin-left: 35px;
    margin-top: 0px;
    height: 91px;

}
.titlePrecense3{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 126px;
    top:-80px;



color: #253D8A;
     
}
.soustitlePrecense3{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 18.5155px;
    line-height: 21px;
    
    color:  rgba(37, 61, 138, 0.5);
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 126px;
    top:-90px;
    
     
}

}
@media screen and (max-width: 992px )  {
    /*Slider*/
.sidebar {
    background-image: url(/static/media/Slider.57f79fcd.png);
    height:581px;
    width: 100%;
}
.buttonslider{
    background: rgba(48, 192, 198, 0.31);
border-radius: 17px;
height: 43px;
width: 190px;
margin-top: 25px;
margin-left: 50px;
border: none;

}
.textbuttonslider{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 45px;
display: flex;
align-items: center;
justify-content: center;
color: #FFFFFF;
}
.buttonslider2{
    background: rgba(48, 192, 198, 0.31);
border-radius: 17px;
height: 63px;
width: 30%;
margin-top:139px;
border: none;

}
.buttonslider3{
    background: rgba(48, 192, 198, 0.31);
border-radius: 17px;
height: 63px;
width: 30%;
margin-top: -50;
border: none;

}
.buttonslider4{
    background: rgba(48, 192, 198, 0.31);
    border-radius: 17px;
    height: 63px;
    width: 30%;
    margin-top: 50px;
    border: none;

}


/*Components /*
/*metier */
.metier{
    background: linear-gradient(101.03deg, #46B7B7 0%, #FFFFFF 100%);
    min-height: 704px;
    height: auto;
    margin-top: 42px !important;
}
.line {
    margin-left: 115px;
    margin-top: 86px;
}
.titlemetier {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 700;
font-size: 34.6105px;
line-height: 63px;
width: 100%;
height: 63px;
display: flex;justify-content: center;
left: 0px;
top:20px;
color: #253D8A;
}
.textmetier {
    display: flex;justify-content: center;
    top:20px;    
height: 116px;
text-align: center;
font-weight: 400;
margin-top:25px;
width: 100%;
left: 10px;
font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 29px;

color: #253D8A;

}
.ITU{
  
    top:-100px;
    left: 90px;
}
.KPMG{
    
    top: 0px;
    left:-40px; 
}
.textitemmetier {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 400;
font-size: 23.9048px;
line-height: 32px;
/* identical to box height */
margin-top: -7px;
display: flex;
height: 15px;
margin-left: 20px;
justify-content: center;
color: #253D8A;
}
.textitemmetier2 {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 400;
font-size: 23.9048px;
line-height: 32px;
/* identical to box height */
display: flex;
height: 15px;
margin-left: 20px;
margin-top: -7px;

justify-content: center;
color: #253D8A;
}
/*Chiffres */
.chiffresback{
    background-image: url(/static/media/chiffres.346637b7.svg);
    height: 188px;
    background-size: 100%;
    align-items:center;    display: flex;justify-content: center;
}
.titrechiffre {
    font-family: 'Tw Cen MT';
font-style: normal;
font-weight: 700;
font-size: 27.1586px;
line-height: 54px;
color: #FFFFFF;
}
/*Presence */


.line2{
    margin-left: 25px;
    margin-top: 0px;
    height: 71px;

}
.titlePrecense1{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 37px;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 20px;
    top:15px;
    
    color: #30C0C6;
     
}
.soustitlePrecense1{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 13.5155px;
    line-height: 21px;
    
    color: rgba(48, 192, 198, 0.5);
    
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 20px;
    top:-10px;
    
     
}
.line3{
    margin-left: 25px;
    margin-top: 0px;
    height: 71px;
}
.titlePrecense2{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 37px;
    /* identical to box height */
    
    
    color: #8BAEE4;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 20px;
    top:15px;
    
     
}
.soustitlePrecense2{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 13.5155px;
    line-height: 21px;
    
    color: rgba(139, 174, 228, 0.5);
    
    /* identical to box height */
    position: relative;
    width: 244px;
    height: 63px;
    left: 20px;
    top:-10px;
     
}
.line4{
    margin-left: 25px;
    margin-top: 0px;
    height: 71px;
}
.titlePrecense3{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 37px;
    /* identical to box height */
    position: relative;
    width: 398px;
    height: 63px;
    left: 20px;
    top:15px;



color: #253D8A;
     
}
.soustitlePrecense3{
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 700;
    font-size: 13.5155px;
    line-height: 21px;
    
    color:  rgba(37, 61, 138, 0.5);
    
    /* identical to box height */
    position: relative;
    width: 240px;
    height: 63px;
     left: 20px;
    top:-10px;
     
}
/*Services & Solutions*/
.card{
    background: #FFFFFF !important;
box-shadow: 0px 12px 24px rgba(44, 225, 194, 0.2) !important;
border-radius: 15px !important;
width:290px !important;
height: 341px !important;
margin-left: 50px;
margin-right: 50px;

margin-top: 46px !important;
}
.buttoncardservice{
    background: #30C0C6;
border-radius: 7.69231px;
width: 247.69px;
height: 38.46px;
margin-top: 90px;
border: none;
position: absolute;
bottom: 17.54px;
}
.textbuttoncardservice{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 23.0769px;
line-height: 27px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}
.card-text {
    font-family: 'Tw Cen MT';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    
    color: #253D8A; 
}
    .titleprecence{
        font-family: 'Tw Cen MT';
        font-style: normal;
        font-weight: 700;
        font-size: 34.6105px;
        line-height: 63px;
        display: flex;
        justify-content: center;        width: 100%;
        height: 63px;
        left: 0px;
        top:50px;
        color: #253D8A;
    }
    .cSNndH{display: flex !important;}
    .carousel-indicators{
        justify-content: left !important;
        bottom: 3px !important;
        left: -40px !important;
        
    }
    .carousel-indicators >button{ width: 7px !important; height: 7px !important; border-radius: 100%; }
    

}

.buttoncontact{
    width:
    202px;
    height:
    38.29px;
    background: #253D8A;}
.textbuttoncontact{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16.2231px;
    line-height: 20px;
    /* identical to box height */
    
    
    color: #FFFFFF;
}
.textcardcontact{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12.9785px;
    line-height: 19px;
    
    color: rgba(37, 61, 138, 0.8);
}
.soustextcardcontact{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12.9785px;
    line-height: 19px;
    
    color: #253D8A;
}
#image{
	margin-top: 10px;
	box-shadow: 5px 5px 5px 5px gray;
	width: 100px;;
	padding: 20px;
	font-weight: 400;
	padding-bottom: 0px;
	height: 60px;
	-webkit-user-select: none;
	        user-select: none;
	text-decoration:line-through;
	font-style: italic;
	font-size: x-large;
	border: #4245f3 2px solid;
	margin-left: 20px;
	
}
#user-input{
	box-shadow: 5px 5px 5px 5px gray;
	width:150px;
	margin-right: 10px;
	padding: 10px;
	padding-bottom: 0px;
	height: 40px;
	/* border: red 0px solid; */
}
input{
	/* border:1px black solid; */
    width: 130px;
}
/* .inline{ */
	/* display:inline-block; */
/* } */
#btn{
	box-shadow: 5px 5px 5px grey;
	color: rgb(255, 255, 255);
	margin: 10px;
	background-color: rgb(61, 42, 165);
    border-radius: 5px;
}
#key{
    color : red;
    font-weight: 100;
    font-size: large;
    margin-left: 10px;
}

.footertitre1{
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 17.0625px;
line-height: 20px;
/* identical to box height */

margin-top: -65px;
margin-left: 542px;
color: #FFFFFF;
}
.footertext1{
    font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 13.27px;
line-height: 16px;
/* identical to box height */
margin-left: 542px;
cursor: pointer;


color: #FFFFFF;
}
.footertitre2{
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 17.0625px;
line-height: 20px;
/* identical to box height */

margin-top: 51px;

color: #FFFFFF;
}
.footertitrebis{
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 12.0625px;
line-height: 20px;
/* identical to box height */

margin-top: 51px;

color: #FFFFFF;
}
.footertext2{
    font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 13.27px;
line-height: 16px;
/* identical to box height */
width: 288px;
cursor: pointer;

color: #FFFFFF;
}
.footer {
    background: #253D8A;
    height: 337px;
    margin-top: 92px !important;
}
.footertitre3{
    font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 17.0625px;
line-height: 20px;
/* identical to box height */

margin-top: -195px;
margin-left: 245px;
color: #FFFFFF;
}
.footertext3{
    font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 13.27px;
line-height: 16px;
/* identical to box height */
margin-left: 245px;



color: #FFFFFF;
}
.footerinput{
    background: #F5F5F5;
border-radius: 23.6979px;
margin-left: 45px;
margin-top: 37px;
border: none;

}
@media screen and (max-width: 1280px ) and (min-width: 768px) {
    .footertitre1{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 17.0625px;
    line-height: 20px;
    /* identical to box height */
    
    margin-top: 60px;
    margin-left: 42px;
    color: #FFFFFF;
    }
    .footertext1{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13.27px;
    line-height: 16px;
    /* identical to box height */
    margin-left: 42px;
    
    
    
    color: #FFFFFF;
    }
    .footertext2{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13.27px;
    line-height: 16px;
    /* identical to box height */
    
    
    
    
    color: #FFFFFF;
    }
    .footertitre3{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 17.0625px;
    line-height: 20px;
    /* identical to box height */
    
    margin-top: 49px;
    margin-left: 42px;
    color: #FFFFFF;
    }
    .footertext3{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13.27px;
    line-height: 16px;
    /* identical to box height */
    margin-left: 42px;
    
    
    
    color: #FFFFFF;
    }
    .footerinput{
        background: #F5F5F5;
    border-radius: 23.6979px;
    margin-left: 8px;
    margin-top: 5px;
    border: none;
    
    }
}
@media screen and (max-width: 992px )  {
    .footer {
        background: #253D8A;
        min-height: 437px;
        margin-top: 92px !important;
        height: auto;
    }
    .footertitre1{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 17.0625px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    justify-content: center;
    margin-top: 49px;
    margin-left: 0px;
    color: #FFFFFF;
    }
    .footertext1{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13.27px;
    line-height: 16px;
    /* identical to box height */
    margin-left: 0px;
    display: flex;
    justify-content: center;
    
    
    color: #FFFFFF;
    }
    .footertext2{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13.27px;
    line-height: 16px;
    /* identical to box height */
    
    
    display: flex;
    justify-content: center;
    
    color: #FFFFFF;
    }
    .footertitre3{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 17.0625px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    justify-content: center;
    margin-top: 49px;
    margin-left: 0px;
    color: #FFFFFF;
    }
    .footertext3{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13.27px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    /* identical to box height */
    margin-left: 0px;
    
    
    
    color: #FFFFFF;
    }
    .footerinput{
        background: #F5F5F5;
    border-radius: 23.6979px;
    margin-left: 0px;
    margin-top: 5px;
    
    border: none;
    
    }
    .footertitre2{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 17.0625px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    justify-content: center;
    margin-top: 49px;
    
    color: #FFFFFF;
    }
}
.buttonmenbarone{
    box-sizing: border-box;

position: absolute;
width: 100px;
height: 40px;
right: 345px;
top: 10px;
cursor: pointer;
background-color:#253D8A ;
border: 0.666667px solid #FFFFFF;
border-radius: 70px;
}

.textbuttonmenbarone {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    
    color: #FFFFFF;
}
.flag-icon {
    border-radius: 50px;
    top:-60%;
margin-left: -12px;
}
.buttonmenbarone2{
    box-sizing: border-box;
cursor: pointer;
position: absolute;
margin-left:20px;
width: 120px;
height: 40px;
right: 210px;
top: 10px;
background-color:#253D8A ;
border: 0.666667px solid #FFFFFF;
border-radius: 70px;
}
.country{
    position: absolute;
width: 29.26px;
height: 22px;
right: 166.88px;
top:10px

}
.searchbar {
    width: 21.53px;height:21.53px; color:#FFFFFF;
    position: absolute;
    right: 118px;
    top: 19.2px;
}
.searchbarinput {
    width: 50px;height:21.53px; background-color: #253D8A;border-color: white;
    position: absolute;
    right: 58px;
    top: 19.2px;
}
.nav-link:focus, .nav-link{
    font-family: 'Poppins' !important;
font-style: normal!important;;
font-weight: 300!important;
font-size: 19.2179px!important;
line-height: 120%!important;
    color: #253d8a!important;
}
.nav-link:focus, .nav-link:hover{
    font-family: 'Poppins' !important;
font-style: normal!important;;
font-weight: 300!important;
font-size: 19.2179px!important;
line-height: 120%!important;
    color: #30C0C6!important;
}
@media screen and (max-width:992px) {
    .buttonmenbarone{
        box-sizing: border-box;
    
    
    width: 100px;
    height: 40px;
   position: static;
    margin-top: 10px;
    background-color:#253D8A ;
    border: 0.666667px solid #FFFFFF;
    border-radius: 70px;
    }
    .textbuttonmenbarone {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        
        color: #FFFFFF;
    }
    .flag-icon {
        border-radius: 50px;
    }
    .buttonmenbarone2{
        box-sizing: border-box;
    
        position: static;
        margin-top: 10px;    
    width: 120px;
    height: 40px;
    right: 110px;
    background-color:#253D8A ;
    border: 0.666667px solid #FFFFFF;
    border-radius: 70px;
    }
    .country{
        position: static;
        margin-top: 10px;       width: 29.26px;
    height: 22px;
    
    }
    .searchbar {
        width: 21.53px;height:21.53px; color:#253D8A;
        position: static;
        margin-top: 15px; 
        margin-left: 25px;
    }
    .searchbarinput {
        width: 50px;height:21.53px; background-color: #253D8A;border-color: white;
        position: static;
        margin-top: 10px; 
    }
    .nav-link:focus, .nav-link{
        font-family: 'Poppins' !important;
    font-style: normal!important;;
    font-weight: 300!important;
    font-size: 19.2179px!important;
    line-height: 120%!important;
        color: #253d8a!important;
    }
    .nav-link:focus, .nav-link:hover{
        font-family: 'Poppins' !important;
    font-style: normal!important;;
    font-weight: 300!important;
    font-size: 19.2179px!important;
    line-height: 120%!important;
        color: #30C0C6!important;
    }
    
}
.titlereference {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 37.669px;
    line-height: 43px;

    color: #253D8A;
    position: relative;
    width: 100%;
    height: 63px;
    left: 126px;
    top: -50px;
}

.soustitleaccteuilte {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;

    color: #253D8A;
}

.textaccteuilte {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    margin-top: 10px;
    color: rgba(37, 61, 138, 0.8);

}

.sousbaraccteuilte {
    width: 96px;
    height: 36px;
    cursor: pointer;

    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height */

    display: flex;
    justify-content: center;
    align-items: center;
    color: #253D8A;
    background: #EFEFEF;
    border-radius: 8px
}

.soustitleaccteuilte2 {
    position: relative;
    width: 100%;
    height: 63px;
    left: 180px;
    top: 25px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 28.16px;
    line-height: 32px;
    display: flex;
    color: #253D8A;
}

.textaccteuilte2 {
    position: relative;
    width: 77%;
    /* height: 63px; */
    left: 180px;
    /* top:25px; */
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;

    color: rgba(37, 61, 138, 0.8);
}

.text2accteuilte {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: left;
    position: relative;
    width: 85%;
    height: 63px;
    left: 126px;
    color: rgba(37, 61, 138, 0.8);
}

.input-icons img {
    position: absolute;
    margin-left: 419px;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.icon {
    padding: 10px;
    min-width: 40px;
}

.input-field {
    width: 483px;
    height: 58.18px;
    padding: 20px;
    text-align: left;
    background: #EFEFEF;
    border-radius: 31.6188px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17.7065px;
    line-height: 21px;

    color: black;
}

.petittextblog {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height */


    color: #253D8A;
}

.input-field::-webkit-input-placeholder {
    width: 483px;
    height: 58.18px;
    padding: 7px;
    text-align: left;
    background: #EFEFEF;
    border-radius: 31.6188px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17.7065px;
    line-height: 21px;

    color: #969696;
}

.input-field::placeholder {
    width: 483px;
    height: 58.18px;
    padding: 7px;
    text-align: left;
    background: #EFEFEF;
    border-radius: 31.6188px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17.7065px;
    line-height: 21px;

    color: #969696;
}

.containerBlog {
    position: relative;
    text-align: center;
    color: white;
    border-radius: 10px;
}

.textBlog {

    position: absolute;
    bottom: 0px;
    height: 56.82px;
    background: rgba(224, 221, 221, 0.58);
    -webkit-backdrop-filter: blur(1.63807px);
            backdrop-filter: blur(1.63807px);
    /* Note: backdrop-filter has minimal browser support */
    width: 100%;
    border-radius: 0px 0px 7.86275px 7.86275px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 11.7941px;
    line-height: 14px;

    color: #3E464D;
}

.textorangecameroun {

    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14.6164px;
    line-height: 17px;
    /* identical to box height */

    margin-left: 48px;
    color: #F78500;
}

.textorangetunisie {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14.6164px;
    line-height: 17px;
    /* identical to box height */

    margin-left: 60px;
    color: #F78500;
}

.textorangeguinée {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14.6164px;
    line-height: 17px;
    /* identical to box height */

    margin-left: 60px;
    color: #F78500;
}

.textorangemaroc {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14.6164px;
    line-height: 17px;
    /* identical to box height */

    margin-left: 64px;
    color: #F78500;
}

@media screen and (max-width:992px) {
    .titlereference {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 43px;

        color: #253D8A;
        position: relative;
        width: 422px;
        height: 63px;
        left: 20px;
        top: 100px;
    }
}
.textabout{
    position: relative;
    width: 461px;
    left: 126px;
top:-45px;

font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 17.2444px;
line-height: 20px;

color: #253D8A;

}
.numabout{
    font-family: 'Hind Siliguri';
font-style: normal;
font-weight: 700;
font-size: 18.9052px;
line-height: 31px;
/* identical to box height */

color: #30C0C6;
}
.text2about{
    font-family: 'Hind Siliguri';
    font-style: normal;
    font-weight: 700;
    font-size: 17.1865px;
    line-height: 28px;
/* identical to box height */

color: #253D8A;
}
.text3about{
    font-family: 'Hind Siliguri';
font-style: normal;
font-weight: 500;
font-size: 11.1713px;
line-height: 18px;
/* identical to box height */

color: #253D8A;
}
.text4about{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14.8981px;
    line-height: 17px;
    text-align: center;
    width: 130px;
    color: #253D8A;
/* identical to box height */

color: #253D8A;
}
.text5about{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 25.9834px;
line-height: 30px;
display: flex;
justify-content: center;
margin-left: 140px;
margin-right: 140px;
color: #253D8A;

}
.textvisionabout{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 37.669px;
line-height: 43px;
/* identical to box height */
margin-left:  124.59px;
margin-top: -60px;

color: #FFFFFF;
}
.textvision{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 41px;
margin-left: 102px;
margin-top: -30px;
color: #FFFFFF;
}
.linevisionabout {
    margin-left: 106px;
    margin-top: 24.35px;
    height: 62.43px;
}
.textvaleurvision1{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 169.99%;
/* or 41px */
margin-left:13px;

color: #30C0C6;
}
.textvaleurvision2{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 169.99%;
/* or 41px */


color: rgba(37, 61, 138, 1)
;
}
.buttondemo{
    background: #253D8A;
box-shadow: 0px 2.9614px 15.5474px -1.4807px rgba(0, 0, 0, 0.09);
border-radius: 74.0351px;
display: flex;
width: 205.08px;
border: none;
height: 45.9px;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 14.807px;
grid-gap: 7.4px;
gap: 7.4px;
}
.textbuttondemo{
    color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
@media screen and (max-width:992px) {
    .textabout{
        display: flex;
        justify-content: center;
        width: 261px;
        left: 0px;
    top:0px;
    text-align: center;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 17.2444px;
    line-height: 20px;
    
    color: #253D8A;
    
    }
    .numabout{
        font-family: 'Hind Siliguri';
    font-style: normal;
    font-weight: 700;
    font-size: 18.9052px;
    line-height: 31px;
    /* identical to box height */
    margin-left: 58.11px;
    
    color: #30C0C6;
    }
    .text2about{
        font-family: 'Hind Siliguri';
        font-style: normal;
        font-weight: 700;
        font-size: 17.1865px;
        line-height: 28px;
    /* identical to box height */
    margin-left: 37.49px;
    
    color: #253D8A;
    }
    .text3about{
        font-family: 'Hind Siliguri';
    font-style: normal;
    font-weight: 500;
    font-size: 11.1713px;
    line-height: 18px;
    /* identical to box height */
    margin-left: 36.46px;
    
    color: #253D8A;
    }
    .text4about{
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 14.8981px;
        line-height: 17px;
        text-align: center;
        width: 130px;
        color: #253D8A;
    /* identical to box height */
    margin-left: 16px;
    
    color: #253D8A;
    }
    .text5about{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 25.9834px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    margin-left: 60px;
    margin-right: 60px;
    color: #253D8A;
    
    }
    .textvisionabout{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 37.669px;
    line-height: 43px;
    /* identical to box height */
    margin-left:  124.59px;
    margin-top: -60px;
    
    color: #FFFFFF;
    }
    .textvision{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 41px;
    display: flex;
    justify-content: center;
    width: 300px;
    margin-left: 20px;
    margin-top: -30px;
    color: #FFFFFF;
    }
    .linevisionabout {
        margin-left: 106px;
        margin-top: 24.35px;
        height: 62.43px;
    }
    .textvaleurvision1{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 169.99%;
    /* or 41px */
    margin-left:63px;
    
    color: #30C0C6;
    }
    .textvaleurvision2{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 169.99%;
    /* or 41px */
    
    
    color: rgba(37, 61, 138, 1)
    ;
    }
    .buttondemo{
        background: #253D8A;
    box-shadow: 0px 2.9614px 15.5474px -1.4807px rgba(0, 0, 0, 0.09);
    border-radius: 74.0351px;
    display: flex;
    width: 205.08px;
    border: none;
    height: 45.9px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14.807px;
    grid-gap: 7.4px;
    gap: 7.4px;
    }
    .textbuttondemo{
        color: #FFFFFF;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
    }
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
.sliderRecrutement{
    box-sizing: border-box;
    z-index: 12;
    background: #FFFFFF;
    border: 0.885014px solid #30C0C6;
    border-radius: 6.1951px;
    width: 60%;
    display:flex;justify-content:center;
    align-items: center;

}
.filterrecurtemnt{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 15.6618px;
line-height: 160%;
width: 20%;
height: 38.06px;
/* identical to box height, or 25px */
border-left: none;
border-right: none;
border-top:none ;
border-color:#253D8A ;
display: flex;
align-items: center;
letter-spacing: 0.48943px;
margin-left: 15px;
color: #253D8A;
}

::-webkit-input-placeholder {

    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 15.6618px;
line-height: 160%;
/* identical to box height, or 25px */

display: flex;
align-items: center;
letter-spacing: 0.48943px;

color: #253D8A;
  }

::placeholder {

    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 15.6618px;
line-height: 160%;
/* identical to box height, or 25px */

display: flex;
align-items: center;
letter-spacing: 0.48943px;

color: #253D8A;
  }
  .titrecardrecrutement{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 22.9704px;
line-height: 160%;
/* identical to box height, or 37px */

display: flex;
align-items: center;
letter-spacing: 0.409777px;

color: rgba(37, 61, 138, 0.8);
  }
  .soustitrecardrecrutement{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 14.8196px;
line-height: 160%;
/* identical to box height, or 24px */

letter-spacing: 0.409777px;

color: rgba(37, 61, 138, 0.8);

  }
  .textcardrecrutement{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 14.8196px;
line-height: 160%;
/* identical to box height, or 24px */

letter-spacing: 0.409777px;

color: rgba(37, 61, 138, 0.8);
  }
  .buttoncardrecrutement{
    width:
79.08px !important;
height:
21.91px !important;
display: flex;
margin-left: 15px;
border: none;
align-items: center;
justify-content: center;
    background: rgba(37, 61, 138, 0.8);
border-radius: 3.81093px;
  }
  .textbuttoncardrecrutement{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 14.8196px;
line-height: 160%;
/* or 24px */


letter-spacing: 0.409777px;

color: rgba(255, 255, 255, 0.8);

  }
  .titlerecutemment{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 160%;
/* or 51px */
margin-top: 83px;
display: flex;
align-items: center;
letter-spacing: 0.48943px;
justify-content: center;
color: #253D8A;
  }
  .buttonpdfrecrutement{
    width: 100%;
    height: 69px;
    display: flex;
    justify-content: center;
    border: none;
    background: rgba(37, 61, 138, 0.63);
border-radius: 7px;
-webkit-transform: matrix(1, 0, 0, 1, 0, 0);
        transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .textbuttonpdfrecrutement{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 22.7036px;
line-height: 160%;
/* or 36px */
justify-content: center

;
display: flex;
align-items: center;
letter-spacing: 0.709486px;

color: #FFFFFF
  }
  .cardrecrutement:hover {
    
box-sizing: border-box;

background: #FFFFFF;
border-left: 2px solid #30C0C6;
-webkit-filter:drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
        filter:drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
  }
 .cardrecrutement:hover .titrecardrecrutement{
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 22.9704px;
  line-height: 160%;
  /* identical to box height, or 37px */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.409777px;
  
color: #30C0C6;
 }
 .cardrecrutement:hover .soustitrecardrecrutement{
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14.8196px;
  line-height: 160%;
  /* identical to box height, or 24px */
  
  letter-spacing: 0.409777px;
  
color: #30C0C6;
 }
 
 .cardrecrutement:hover .buttoncardrecrutement{
  width: 100%;
  box-sizing: border-box;

  height: 69px;
  border: none;
  background: rgba(37, 61, 138, 0.63);
border-radius: 7px;
-webkit-transform: matrix(1, 0, 0, 1, 0, 0);
        transform: matrix(1, 0, 0, 1, 0, 0);
background: #30C0C6;

 }
 
.cardrecrutement:hover .textbuttoncardrecrutement{
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 15.5549px;
  line-height: 160%;
  /* or 25px */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.430107px;
  
  color: #FFFFFF;
 }
 .pagination {
  --bs-pagination-color: #4D4F5C
 }
 .active>.page-link, .page-link.active {
  background-color: #30C0C6 !important;
 }

.titleservicearichi{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 34.6555px;
    line-height: 40px;
    /* identical to box height */
    position: relative;
    width: 428px;
    height: 40px;
    left: 126px;
    top:-45px;
    color: #253D8A;
}
.textservicearchi{
    position: relative;
    width: 461px;
    height: 259px;
    left: 126px;
    overflow-y: scroll;
top:0px;
font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 28px;

color: rgba(0, 0, 0, 0.51);

}
::-webkit-scrollbar {
    display: none;
}
.imageservicearchi{
    position: relative;
    left: 126px;
   margin-top: 20px;

}
.titleserviceconsulting{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 34.6555px;
line-height: 40px;
/* identical to box height */
position: relative;
width: 100%;
height: 63px;
left: 126px;
top:-45px;
color: #253D8A;

}
.lineserviceconsulting{
    margin-left: 106px;
    margin-top: 24.35px;
}
.textserviceconsulting{
    position: relative;
    width: 80%;
    height: 200px;
    overflow-y: scroll;
    left: 126px;
top:-45px;
font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 28px;

color: rgba(0, 0, 0, 0.51);

}
.imageserviceconsulting{
    position: relative;
    left: 126px;

}
.title2serviceconsulting{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 34.6555px;
line-height: 40px;
/* identical to box height */
position: relative;
width: 536px;
height: 40px;
left: 126px;
top:-45px;
color: #253D8A;

}
.titleserviceconsultingcontact{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 37px;
/* identical to box height */
position: relative;
width: 395px;
height: 63px;

top:45px;

color: #253D8A;
}
.textAutreservice{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 32px;
text-align: center;
color: #253D8A;
}
.buttonAutreservice{
    background: #30C0C6;
border-radius: 7.69231px;
width: 247.69px;
height: 38.46px;
margin-top: 35px;
border: none;
}
.buttonsubmitservice{
    width: 161px;
height: 35px;
justify-content: center;
position: absolute;
right: 30px;
display: flex;
background: #30C0C6;
border-radius: 5px;border: none;
}
.textsubmitservice{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 20px;
margin-top: 5px;
width: 103px;
line-height: 23px;
text-align: center;

color: #FFFFFF;
}
@media screen and (max-width:992px) {
    .titleservicearichi{
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 34.6555px;
        line-height: 40px;
        /* identical to box height */
        position: relative;
        width: 428px;
        height: 40px;
        left: 126px;
        top:-45px;
        color: #253D8A;
    }
    .textservicearchi{
        position: relative;
        width: 60%;

        height: 259px;
        left: 126px;
        overflow-y: scroll;
    top:0px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    
    color: rgba(0, 0, 0, 0.51);
    
    }
    ::-webkit-scrollbar {
        display: none;
    }
    .imageservicearchi{
        position: relative;
        left: 126px;
       margin-top: 20px;
    
    }
    .titleserviceconsulting{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 24.6555px;
    line-height: 40px;
    /* identical to box height */
    display: flex;
    justify-content: center;
    width: 395px;
    height: 63px;
    left: 0px;
    top:0px;
    color: #253D8A;
    
top:0px;
    }
    .lineserviceconsulting{
        
        margin-top: 0px;
    }
    .textserviceconsulting{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 159px;
        left: 0px;
    top:0px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    overflow-y: scroll;
    line-height: 28px;
    
    color: rgba(0, 0, 0, 0.51);
    
    }
    .imageserviceconsulting{
        display: flex;
        justify-content: center;
        
        left: 0px;
        margin-top: 20px;
    
    }
    .title2serviceconsulting{
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 24.6555px;
        line-height: 40px;
        /* identical to box height */
       
        
        color: #253D8A;
        /* identical to box height */
        display: flex;
        justify-content: center;
        width: 395px;
        height: 63px;
        left: 0px;
        top:0px;
        
    top:0px;
    
    }
    .titleserviceconsultingcontact{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    display: flex;
    justify-content: center;
    width: 395px;
    height: 63px;
    
    top:45px;
    
    color: #253D8A;
    }
    .textAutreservice{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    
    color: #253D8A;
    }
    .buttonAutreservice{
        background: #30C0C6;
    border-radius: 7.69231px;
    width: 247.69px;
    height: 38.46px;
    margin-top: 35px;
    border: none;
    }
 
    .textsubmitservice{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 5px;
    width: 103px;
    line-height: 23px;
    text-align: center;
    
    color: #FFFFFF;
    }
}
.titlesolutionresaux{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 34.6555px;
    line-height: 40px;
    /* identical to box height */
    margin-top: 57px;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
}
.navsolutionresaux{
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    margin-left: 104px;
    margin-top:59px;
    width: 80%;
}
.navsolutionresauxbutton{
width: 250px !important;
height: 33;
margin-left: 15px;
background-color: transparent;
box-sizing: border-box;

border: 0.673575px solid #FFFFFF;
border-radius: 8.0829px;
}
.navsolutionresauxtext{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 21.5544px;
line-height: 25px;
display: flex;
justify-content: center;
/* identical to box height */
width: 100%;
height: 25px;
color: #FFFFFF;
}
.titlesolutionresauxbox{
font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 28.1624px;
line-height: 32px;

color: #253D8A;
}
.textsolutionresauxbox{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 25.0797px;
    line-height: 29px;
    
    color: #253D8A;
    }
    .imageresauxbox{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media screen and (min-width:992px ) and (max-width:1280px) {
        .titlesolutionresaux{
            font-family: 'Arial';
            font-style: normal;
            font-weight: 700;
            font-size: 23px !important;
            line-height: 40px;
            /* identical to box height */
            margin-top: 57px;
            display: flex;
            justify-content: center;
            color: #FFFFFF;
        }
       
        .navsolutionresaux{
            overflow-x: scroll;
            display: flex;
            justify-content: center;
            margin-left: 20px;
            margin-top:59px;
            width: 100%;
        }
        .navsolutionresauxbutton{
        width: 130px;
        height: 33;
        margin-left: 25px;
        background-color: transparent;
        box-sizing: border-box;
        
        border: 0.673575px solid #FFFFFF;
        border-radius: 8.0829px;
        }
        .navsolutionresauxtext{
            font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 21.5544px;
        line-height: 25px;
        display: flex;
        justify-content: center;
        /* identical to box height */
        width: 100%;
        height: 25px;
        color: #FFFFFF;
        }
        .titlesolutionresauxbox{
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 28.1624px;
        line-height: 32px;
        
        color: #253D8A;
        }
        .textsolutionresauxbox{
            font-family: 'Arial';
            font-style: normal;
            font-weight: 400;
            font-size: 25.0797px;
            line-height: 29px;
            text-align: center;
            color: #253D8A;
            }
            .imageresauxbox{
                display: flex;
                justify-content: center;
                margin-top: 60px;
                
                align-items: center;
            }
    }
    
    @media screen and (max-width: 992px ) {
       
        .titlesolutionresaux{
            font-family: 'Arial';
            font-style: normal;
            font-weight: 700;
            font-size: 23px !important;
            line-height: 40px;
            /* identical to box height */
            margin-top: 57px;
            display: flex;
            justify-content: center;
            color: #FFFFFF;
        }
       
        .navsolutionresaux{
            overflow-x: scroll;
            display: flex;
            justify-content: center;
            margin-left: 20px;
            margin-top:59px;
            width: 100%;
        }
        .navsolutionresauxbutton{
        width: 130px;
        height: 33;
        margin-left: 25px;
        background-color: transparent;
        box-sizing: border-box;
        
        border: 0.673575px solid #FFFFFF;
        border-radius: 8.0829px;
        }
        .navsolutionresauxtext{
            font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 21.5544px;
        line-height: 25px;
        display: flex;
        justify-content: center;
        /* identical to box height */
        width: 116px;
        height: 25px;
        color: #FFFFFF;
        }
        .titlesolutionresauxbox{
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 28.1624px;
        line-height: 32px;
        
        color: #253D8A;
        }
        .textsolutionresauxbox{
            font-family: 'Arial';
            font-style: normal;
            font-weight: 400;
            font-size: 25.0797px;
            line-height: 29px;
            text-align: center;
            color: #253D8A;
            }
            .imageresauxbox{
                display: flex;
                justify-content: center;
                margin-top: 60px;
                
                align-items: center;
            }
    }
