.titleservicearichi{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 34.6555px;
    line-height: 40px;
    /* identical to box height */
    position: relative;
    width: 428px;
    height: 40px;
    left: 126px;
    top:-45px;
    color: #253D8A;
}
.textservicearchi{
    position: relative;
    width: 461px;
    height: 259px;
    left: 126px;
    overflow-y: scroll;
top:0px;
font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 28px;

color: rgba(0, 0, 0, 0.51);

}
::-webkit-scrollbar {
    display: none;
}
.imageservicearchi{
    position: relative;
    left: 126px;
   margin-top: 20px;

}
.titleserviceconsulting{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 34.6555px;
line-height: 40px;
/* identical to box height */
position: relative;
width: 100%;
height: 63px;
left: 126px;
top:-45px;
color: #253D8A;

}
.lineserviceconsulting{
    margin-left: 106px;
    margin-top: 24.35px;
}
.textserviceconsulting{
    position: relative;
    width: 80%;
    height: 200px;
    overflow-y: scroll;
    left: 126px;
top:-45px;
font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 28px;

color: rgba(0, 0, 0, 0.51);

}
.imageserviceconsulting{
    position: relative;
    left: 126px;

}
.title2serviceconsulting{
    font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 34.6555px;
line-height: 40px;
/* identical to box height */
position: relative;
width: 536px;
height: 40px;
left: 126px;
top:-45px;
color: #253D8A;

}
.titleserviceconsultingcontact{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 37px;
/* identical to box height */
position: relative;
width: 395px;
height: 63px;

top:45px;

color: #253D8A;
}
.textAutreservice{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 32px;
text-align: center;
color: #253D8A;
}
.buttonAutreservice{
    background: #30C0C6;
border-radius: 7.69231px;
width: 247.69px;
height: 38.46px;
margin-top: 35px;
border: none;
}
.buttonsubmitservice{
    width: 161px;
height: 35px;
justify-content: center;
position: absolute;
right: 30px;
display: flex;
background: #30C0C6;
border-radius: 5px;border: none;
}
.textsubmitservice{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 20px;
margin-top: 5px;
width: 103px;
line-height: 23px;
text-align: center;

color: #FFFFFF;
}
@media screen and (max-width:992px) {
    .titleservicearichi{
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 34.6555px;
        line-height: 40px;
        /* identical to box height */
        position: relative;
        width: 428px;
        height: 40px;
        left: 126px;
        top:-45px;
        color: #253D8A;
    }
    .textservicearchi{
        position: relative;
        width: 60%;

        height: 259px;
        left: 126px;
        overflow-y: scroll;
    top:0px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    
    color: rgba(0, 0, 0, 0.51);
    
    }
    ::-webkit-scrollbar {
        display: none;
    }
    .imageservicearchi{
        position: relative;
        left: 126px;
       margin-top: 20px;
    
    }
    .titleserviceconsulting{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 24.6555px;
    line-height: 40px;
    /* identical to box height */
    display: flex;
    justify-content: center;
    width: 395px;
    height: 63px;
    left: 0px;
    top:0px;
    color: #253D8A;
    
top:0px;
    }
    .lineserviceconsulting{
        
        margin-top: 0px;
    }
    .textserviceconsulting{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 159px;
        left: 0px;
    top:0px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    overflow-y: scroll;
    line-height: 28px;
    
    color: rgba(0, 0, 0, 0.51);
    
    }
    .imageserviceconsulting{
        display: flex;
        justify-content: center;
        
        left: 0px;
        margin-top: 20px;
    
    }
    .title2serviceconsulting{
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 24.6555px;
        line-height: 40px;
        /* identical to box height */
       
        
        color: #253D8A;
        /* identical to box height */
        display: flex;
        justify-content: center;
        width: 395px;
        height: 63px;
        left: 0px;
        top:0px;
        
    top:0px;
    
    }
    .titleserviceconsultingcontact{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
    /* identical to box height */
    display: flex;
    justify-content: center;
    width: 395px;
    height: 63px;
    
    top:45px;
    
    color: #253D8A;
    }
    .textAutreservice{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    
    color: #253D8A;
    }
    .buttonAutreservice{
        background: #30C0C6;
    border-radius: 7.69231px;
    width: 247.69px;
    height: 38.46px;
    margin-top: 35px;
    border: none;
    }
 
    .textsubmitservice{
        font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 5px;
    width: 103px;
    line-height: 23px;
    text-align: center;
    
    color: #FFFFFF;
    }
}