.buttonmenbarone{
    box-sizing: border-box;

position: absolute;
width: 100px;
height: 40px;
right: 345px;
top: 10px;
cursor: pointer;
background-color:#253D8A ;
border: 0.666667px solid #FFFFFF;
border-radius: 70px;
}

.textbuttonmenbarone {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    
    color: #FFFFFF;
}
.flag-icon {
    border-radius: 50px;
    top:-60%;
margin-left: -12px;
}
.buttonmenbarone2{
    box-sizing: border-box;
cursor: pointer;
position: absolute;
margin-left:20px;
width: 120px;
height: 40px;
right: 210px;
top: 10px;
background-color:#253D8A ;
border: 0.666667px solid #FFFFFF;
border-radius: 70px;
}
.country{
    position: absolute;
width: 29.26px;
height: 22px;
right: 166.88px;
top:10px

}
.searchbar {
    width: 21.53px;height:21.53px; color:#FFFFFF;
    position: absolute;
    right: 118px;
    top: 19.2px;
}
.searchbarinput {
    width: 50px;height:21.53px; background-color: #253D8A;border-color: white;
    position: absolute;
    right: 58px;
    top: 19.2px;
}
.nav-link:focus, .nav-link{
    font-family: 'Poppins' !important;
font-style: normal!important;;
font-weight: 300!important;
font-size: 19.2179px!important;
line-height: 120%!important;
    color: #253d8a!important;
}
.nav-link:focus, .nav-link:hover{
    font-family: 'Poppins' !important;
font-style: normal!important;;
font-weight: 300!important;
font-size: 19.2179px!important;
line-height: 120%!important;
    color: #30C0C6!important;
}
@media screen and (max-width:992px) {
    .buttonmenbarone{
        box-sizing: border-box;
    
    
    width: 100px;
    height: 40px;
   position: static;
    margin-top: 10px;
    background-color:#253D8A ;
    border: 0.666667px solid #FFFFFF;
    border-radius: 70px;
    }
    .textbuttonmenbarone {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        
        color: #FFFFFF;
    }
    .flag-icon {
        border-radius: 50px;
    }
    .buttonmenbarone2{
        box-sizing: border-box;
    
        position: static;
        margin-top: 10px;    
    width: 120px;
    height: 40px;
    right: 110px;
    background-color:#253D8A ;
    border: 0.666667px solid #FFFFFF;
    border-radius: 70px;
    }
    .country{
        position: static;
        margin-top: 10px;       width: 29.26px;
    height: 22px;
    
    }
    .searchbar {
        width: 21.53px;height:21.53px; color:#253D8A;
        position: static;
        margin-top: 15px; 
        margin-left: 25px;
    }
    .searchbarinput {
        width: 50px;height:21.53px; background-color: #253D8A;border-color: white;
        position: static;
        margin-top: 10px; 
    }
    .nav-link:focus, .nav-link{
        font-family: 'Poppins' !important;
    font-style: normal!important;;
    font-weight: 300!important;
    font-size: 19.2179px!important;
    line-height: 120%!important;
        color: #253d8a!important;
    }
    .nav-link:focus, .nav-link:hover{
        font-family: 'Poppins' !important;
    font-style: normal!important;;
    font-weight: 300!important;
    font-size: 19.2179px!important;
    line-height: 120%!important;
        color: #30C0C6!important;
    }
    
}