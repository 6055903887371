.titlereference {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 37.669px;
    line-height: 43px;

    color: #253D8A;
    position: relative;
    width: 100%;
    height: 63px;
    left: 126px;
    top: -50px;
}

.soustitleaccteuilte {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;

    color: #253D8A;
}

.textaccteuilte {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    margin-top: 10px;
    color: rgba(37, 61, 138, 0.8);

}

.sousbaraccteuilte {
    width: 96px;
    height: 36px;
    cursor: pointer;

    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height */

    display: flex;
    justify-content: center;
    align-items: center;
    color: #253D8A;
    background: #EFEFEF;
    border-radius: 8px
}

.soustitleaccteuilte2 {
    position: relative;
    width: 100%;
    height: 63px;
    left: 180px;
    top: 25px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 28.16px;
    line-height: 32px;
    display: flex;
    color: #253D8A;
}

.textaccteuilte2 {
    position: relative;
    width: 77%;
    /* height: 63px; */
    left: 180px;
    /* top:25px; */
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;

    color: rgba(37, 61, 138, 0.8);
}

.text2accteuilte {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: left;
    position: relative;
    width: 85%;
    height: 63px;
    left: 126px;
    color: rgba(37, 61, 138, 0.8);
}

.input-icons img {
    position: absolute;
    margin-left: 419px;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.icon {
    padding: 10px;
    min-width: 40px;
}

.input-field {
    width: 483px;
    height: 58.18px;
    padding: 20px;
    text-align: left;
    background: #EFEFEF;
    border-radius: 31.6188px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17.7065px;
    line-height: 21px;

    color: black;
}

.petittextblog {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height */


    color: #253D8A;
}

.input-field::placeholder {
    width: 483px;
    height: 58.18px;
    padding: 7px;
    text-align: left;
    background: #EFEFEF;
    border-radius: 31.6188px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17.7065px;
    line-height: 21px;

    color: #969696;
}

.containerBlog {
    position: relative;
    text-align: center;
    color: white;
    border-radius: 10px;
}

.textBlog {

    position: absolute;
    bottom: 0px;
    height: 56.82px;
    background: rgba(224, 221, 221, 0.58);
    backdrop-filter: blur(1.63807px);
    /* Note: backdrop-filter has minimal browser support */
    width: 100%;
    border-radius: 0px 0px 7.86275px 7.86275px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 11.7941px;
    line-height: 14px;

    color: #3E464D;
}

.textorangecameroun {

    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14.6164px;
    line-height: 17px;
    /* identical to box height */

    margin-left: 48px;
    color: #F78500;
}

.textorangetunisie {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14.6164px;
    line-height: 17px;
    /* identical to box height */

    margin-left: 60px;
    color: #F78500;
}

.textorangeguinée {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14.6164px;
    line-height: 17px;
    /* identical to box height */

    margin-left: 60px;
    color: #F78500;
}

.textorangemaroc {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14.6164px;
    line-height: 17px;
    /* identical to box height */

    margin-left: 64px;
    color: #F78500;
}

@media screen and (max-width:992px) {
    .titlereference {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 43px;

        color: #253D8A;
        position: relative;
        width: 422px;
        height: 63px;
        left: 20px;
        top: 100px;
    }
}