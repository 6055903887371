.titlesolutionresaux{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 34.6555px;
    line-height: 40px;
    /* identical to box height */
    margin-top: 57px;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
}
.navsolutionresaux{
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    margin-left: 104px;
    margin-top:59px;
    width: 80%;
}
.navsolutionresauxbutton{
width: 250px !important;
height: 33;
margin-left: 15px;
background-color: transparent;
box-sizing: border-box;

border: 0.673575px solid #FFFFFF;
border-radius: 8.0829px;
}
.navsolutionresauxtext{
    font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 21.5544px;
line-height: 25px;
display: flex;
justify-content: center;
/* identical to box height */
width: 100%;
height: 25px;
color: #FFFFFF;
}
.titlesolutionresauxbox{
font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 28.1624px;
line-height: 32px;

color: #253D8A;
}
.textsolutionresauxbox{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 25.0797px;
    line-height: 29px;
    
    color: #253D8A;
    }
    .imageresauxbox{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media screen and (min-width:992px ) and (max-width:1280px) {
        .titlesolutionresaux{
            font-family: 'Arial';
            font-style: normal;
            font-weight: 700;
            font-size: 23px !important;
            line-height: 40px;
            /* identical to box height */
            margin-top: 57px;
            display: flex;
            justify-content: center;
            color: #FFFFFF;
        }
       
        .navsolutionresaux{
            overflow-x: scroll;
            display: flex;
            justify-content: center;
            margin-left: 20px;
            margin-top:59px;
            width: 100%;
        }
        .navsolutionresauxbutton{
        width: 130px;
        height: 33;
        margin-left: 25px;
        background-color: transparent;
        box-sizing: border-box;
        
        border: 0.673575px solid #FFFFFF;
        border-radius: 8.0829px;
        }
        .navsolutionresauxtext{
            font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 21.5544px;
        line-height: 25px;
        display: flex;
        justify-content: center;
        /* identical to box height */
        width: 100%;
        height: 25px;
        color: #FFFFFF;
        }
        .titlesolutionresauxbox{
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 28.1624px;
        line-height: 32px;
        
        color: #253D8A;
        }
        .textsolutionresauxbox{
            font-family: 'Arial';
            font-style: normal;
            font-weight: 400;
            font-size: 25.0797px;
            line-height: 29px;
            text-align: center;
            color: #253D8A;
            }
            .imageresauxbox{
                display: flex;
                justify-content: center;
                margin-top: 60px;
                
                align-items: center;
            }
    }
    
    @media screen and (max-width: 992px ) {
       
        .titlesolutionresaux{
            font-family: 'Arial';
            font-style: normal;
            font-weight: 700;
            font-size: 23px !important;
            line-height: 40px;
            /* identical to box height */
            margin-top: 57px;
            display: flex;
            justify-content: center;
            color: #FFFFFF;
        }
       
        .navsolutionresaux{
            overflow-x: scroll;
            display: flex;
            justify-content: center;
            margin-left: 20px;
            margin-top:59px;
            width: 100%;
        }
        .navsolutionresauxbutton{
        width: 130px;
        height: 33;
        margin-left: 25px;
        background-color: transparent;
        box-sizing: border-box;
        
        border: 0.673575px solid #FFFFFF;
        border-radius: 8.0829px;
        }
        .navsolutionresauxtext{
            font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 21.5544px;
        line-height: 25px;
        display: flex;
        justify-content: center;
        /* identical to box height */
        width: 116px;
        height: 25px;
        color: #FFFFFF;
        }
        .titlesolutionresauxbox{
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 28.1624px;
        line-height: 32px;
        
        color: #253D8A;
        }
        .textsolutionresauxbox{
            font-family: 'Arial';
            font-style: normal;
            font-weight: 400;
            font-size: 25.0797px;
            line-height: 29px;
            text-align: center;
            color: #253D8A;
            }
            .imageresauxbox{
                display: flex;
                justify-content: center;
                margin-top: 60px;
                
                align-items: center;
            }
    }